document.addEventListener("DOMContentLoaded", () => {
  const recipe = {
    init: function() {
      if ($("#recipe")) this.setActions();
    },

    setActions: function() {
      $("[data-onclick=printPage]").click(this.printPage);
    },

    printPage: function() {
      window.print();
    }
  };

  recipe.init();
  window.recipe = recipe;
});

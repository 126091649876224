import Vue from "vue/dist/vue.esm";
import Vuelidate from "vuelidate";
import { required, between, maxValue, email } from "vuelidate/lib/validators";

Vue.use(Vuelidate);

document.addEventListener("DOMContentLoaded", () => {
  if ($("#meal-plan-form").length) {
    initializeMealPlanForm();
  }
});

function initializeMealPlanForm() {
  new Vue({
    el: "#meal-plan-form",
    data: {
      step: 0,
      steps: [
        "introduction",
        "familiarity_level",
        "activity_level",
        "allowed_foods_meats",
        "allowed_foods_vegetables",
        "allowed_foods_allergens",
        "measurements_age",
        "measurements_weight",
        "loading",
        "email"
      ],
      vegetarian: false,
      loader: {
        interval_time: 200,
        percentage: 0
      },
      mealPlan: {
        age: 0,
        height_cm: null,
        weight_kg: null,
        target_weight_kg: null
      },
      height_detailed_in: null,
      height_detailed_feet: null
    },
    validations() {
      return {
        mealPlan: {
          age: { required, between: between(18, 150) },
          height_cm: { required, between: between(100, 300) },
          weight_kg: { required, between: between(40, 300) },
          target_weight_kg: {
            required,
            maxValue: maxValue(this.mealPlan.weight_kg)
          },
          height_in: {},
          weight_lb: { required, between: between(80, 800) },
          target_weight_lb: {
            required,
            maxValue: maxValue(this.mealPlan.weight_lb)
          },
          email: { required, email }
        },
        height_detailed_in: { required },
        height_detailed_feet: { required, between: between(3, 10) }
      };
    },
    methods: {
      nextStep() {
        if (this.step === this.steps.indexOf("measurements_age")) {
          this.$v.mealPlan.age.$touch();
          this.$v.mealPlan.height_cm.$touch();
          this.$v.height_detailed_feet.$touch();
          this.$v.height_detailed_in.$touch();

          if (this.validMeasurementsAge()) {
            this.step += 1;
          }
        } else if (this.step === this.steps.indexOf("measurements_weight")) {
          this.$v.mealPlan.weight_kg.$touch();
          this.$v.mealPlan.target_weight_kg.$touch();
          this.$v.mealPlan.weight_lb.$touch();
          this.$v.mealPlan.target_weight_lb.$touch();
          if (this.mealPlan.preferred_measurement_system === "imperial") {
            this.changeHeightInches();
          }

          if (this.validMeasurementsWeight()) {
            this.step += 1;
          }
        } else if (this.step === this.steps.indexOf("email")) {
          this.$v.mealPlan.email.$touch();

          if (this.isValid(this.$v.mealPlan.email)) {
            this.step += 1;
          }
        } else {
          this.step += 1;
        }

        if (this.step === this.steps.indexOf("loading")) {
          this.startLoading();
        }

        if (this.step === this.steps.length) {
          $("#meal-plan-form form").submit();
        } else {
          this.pushHistory();
        }
      },
      resetStep() {
        var params = new URLSearchParams(window.location.search);
        params.delete("step");

        history.replaceState(
          {},
          document.title,
          window.location.pathname + "?" + params.toString()
        );
      },
      setStep() {
        var params = new URLSearchParams(window.location.search);

        if (params.get("step")) {
          this.step = parseInt(params.get("step"));
        } else {
          this.step = 0;
        }
      },
      pushHistory() {
        var params = new URLSearchParams(window.location.search);
        params.set("step", this.step);

        history.pushState(
          { step: this.step },
          document.title,
          window.location.pathname + "?" + params.toString()
        );
      },
      changeAllowedMeats() {
        if (this.mealPlan.allowed_foods_meats.length > 0) {
          this.vegetarian = false;
        } else {
          this.vegetarian = true;
        }
      },
      changeVegetarian() {
        if (this.vegetarian === true) {
          this.mealPlan.allowed_foods_meats = [];
        } else if (this.vegetarian === false) {
          this.mealPlan.allowed_foods_meats = this.meatValues();
        }
      },
      meatValues() {
        var meatInputs = $(
          '.option-card__input[name="meal_plan[allowed_foods_meats][]"]'
        );

        return meatInputs.toArray().map(input => input.value);
      },
      changeMeasurementSystem() {
        if (this.mealPlan.preferred_measurement_system === "imperial") {
          this.mealPlan.height_cm = null;
          this.mealPlan.weight_kg = null;
          this.mealPlan.target_weight_kg = null;
          this.height_detailed_in = null;
          this.height_detailed_feet = null;
        } else if (this.mealPlan.preferred_measurement_system === "metric") {
          this.mealPlan.height_in = null;
          this.mealPlan.weight_lb = null;
          this.mealPlan.target_weight_lb = null;
        }
      },
      changeHeightInches() {
        this.mealPlan.height_in =
          parseInt(this.height_detailed_in) +
          parseInt(this.height_detailed_feet) * 12;
      },
      showNext() {
        switch (this.step) {
          case this.steps.indexOf("introduction"):
            return false;
          case this.steps.indexOf("familiarity_level"):
            return this.mealPlan.familiarity_level !== null;
          case this.steps.indexOf("activity_level"):
            return this.mealPlan.activity_level !== null;
          case this.steps.indexOf("allowed_foods_meats"):
            return true;
          case this.steps.indexOf("allowed_foods_vegetables"):
            return true;
          case this.steps.indexOf("allowed_foods_allergens"):
            return true;
          case this.steps.indexOf("measurements_age"):
            return this.validMeasurementsAge();
          case this.steps.indexOf("measurements_weight"):
            return this.validMeasurementsWeight();
          case this.steps.indexOf("email"):
            return this.isValid(this.$v.mealPlan.email);
          case this.steps.indexOf("loading"):
            return false;
          default:
            return false;
        }
      },
      nextDisabled() {
        switch (this.step) {
          case this.steps.indexOf("allowed_foods_meats"):
            return (
              this.mealPlan.allowed_foods_meats.length === 0 &&
              this.vegetarian === false
            );
          case this.steps.indexOf("allowed_foods_vegetables"):
            return this.mealPlan.allowed_foods_vegetables.length === 0;
          case this.steps.indexOf("allowed_foods_allergens"):
            return this.mealPlan.allowed_foods_allergens.length === 0;
          default:
            return false;
        }
      },
      validMeasurementsAge() {
        const validAge = this.isValid(this.$v.mealPlan.age);
        const validMetric = this.isValid(this.$v.mealPlan.height_cm);
        const validImperial = this.validImperial();

        return validAge && (validMetric || validImperial);
      },
      validImperial() {
        return (
          this.isValid(this.$v.height_detailed_feet) &&
          this.isValid(this.$v.height_detailed_in)
        );
      },
      validMeasurementsWeight() {
        const validMetric =
          this.isValid(this.$v.mealPlan.weight_kg) &&
          this.isValid(this.$v.mealPlan.target_weight_kg);
        const validImperial =
          this.isValid(this.$v.mealPlan.weight_lb) &&
          this.isValid(this.$v.mealPlan.target_weight_lb);

        return validMetric || validImperial;
      },
      progressbarPercentage() {
        const totalSteps = this.steps.length - 3;
        return (this.step / totalSteps) * 100;
      },
      status(validation) {
        if (validation.$error) {
          return "is-invalid";
        } else if (validation.$dirty) {
          return "is-valid";
        }
      },
      statusDouble(validation1, validation2) {
        if (validation1.$error || validation2.$error) {
          return "is-invalid";
        } else if (validation1.$dirty && validation2.$dirty) {
          return "is-valid";
        }
      },
      startLoading() {
        const that = this;
        const loader = this.loader;

        function percentageToDegrees(percentage) {
          return (percentage / 100) * 360;
        }

        const interval = setInterval(function() {
          const next_percentage_value =
            loader.percentage + Math.floor(Math.random() * 10 + 1);
          loader.percentage = Math.min(next_percentage_value, 100);

          $(".progress-circle").each(function() {
            var value = $(this).attr("data-value");
            var left = $(this).find(".progress-left .progress-bar");
            var right = $(this).find(".progress-right .progress-bar");
            if (value > 0) {
              if (value <= 50) {
                right.css(
                  "transform",
                  "rotate(" + percentageToDegrees(value) + "deg)"
                );
              } else {
                right.css("transform", "rotate(180deg)");
                left.css(
                  "transform",
                  "rotate(" + percentageToDegrees(value - 50) + "deg)"
                );
              }
            }
          });

          if (loader.percentage >= 100) {
            this.clearInterval(interval);
            that.nextStep();
          }
        }, loader.interval_time);
      },
      isValid(validation) {
        return !validation.$error && validation.$dirty;
      },
      vegetarianStyle() {
        if (this.vegetarian === true) {
          return "option-card--selected";
        } else {
          return "option-card--indeterminate";
        }
      }
    },
    beforeMount() {
      this.mealPlan = JSON.parse(this.$el.dataset.mealPlan);

      if (this.mealPlan.height_in !== null) {
        this.height_detailed_feet = parseInt(this.mealPlan.height_in / 12);
        this.height_detailed_in = parseInt(
          Math.round(this.mealPlan.height_in - this.height_detailed_feet * 12)
        );
      }
    },
    mounted() {
      this.resetStep();

      window.onpopstate = () => {
        this.setStep();
      };
    }
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const weeklyPlan = {
    init: function() {
      if ($("#weekly-plan")) this.setActions();
    },

    setActions: function() {
      $("[data-onchange=goToWeekPage]").change(this.goToWeekPage);
    },

    goToWeekPage: function() {
      window.location.replace($(this).val());
    }
  };

  weeklyPlan.init();
});

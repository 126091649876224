import "jquery-mask-plugin/dist/jquery.mask";

document.addEventListener("DOMContentLoaded", () => {
  const checkoutForm = {
    init: function() {
      if ($("#checkout")) this.setActions();
    },

    setActions: function() {
      $("[data-mask=creditCard]").mask("0000 0000 0000 0000", {});
      $("[data-mask=expDate]").mask("0Z/0000", {
        translation: { Z: { pattern: /[0-9]/, optional: true } }
      });

      $("[data-onclick=selectPlan]").click(this.selectPlan);
      $("[data-onclick=selectPlanFromCheckedInput]").click(
        this.selectPlanFromCheckedInput
      );

      $(".checkout-pricing__card").on("click", function() {
        $(".checkout-pricing__card.is-active").removeClass("is-active");
        $(this).addClass("is-active");

        $(".checkout-pricing__card__details.is-mobile ul").html(
          $(this)
            .find(".checkout-pricing__card__details ul")
            .html()
        );
      });

      if ($("input[name='checkout_form[payment_plan_key]']").val() == "") {
        $(".checkout-pricing__card.featured input").trigger("click");
      } else {
        $("input:checked[data-plan]").trigger("click");
      }
    },

    selectPlan: function() {
      const plan = $(this).data("plan");

      $("input[name='checkout_form[payment_plan_key]']").val(plan.key);
      $("[data=selected-plan-amount-interval]").html(plan.text);
    },

    selectPlanFromCheckedInput: function() {
      const plan = $("input:checked[data-plan]").data("plan");

      $("#checkout_form_payment_plan_key").val(plan.key);
    }
  };

  checkoutForm.init();
  window.checkoutForm = checkoutForm;
});
